<template>
  <span v-if="hasPerm('payments.change_invoice') && invoice.showFrozen()" class="invoice-freeze">
    <loading-gif :loading-name="loadingName" short></loading-gif>
    <span v-if="invoice.frozen">
      <a
        href
        @click.prevent="freezeInvoice(null)"
        class="btn btn-secondary btn-sm"
      >
        <i class="fa fa-thumbs-up"></i>
        Débloquer la facture
      </a>
    </span>
    <span v-else>
      <a
        v-for="freezer of invoice.freezers" :key="freezer.id"
        @click.prevent="freezeInvoice(freezer)"
        href
        class="btn btn-secondary btn-sm"
      >
        <i class="fa fa-hand"></i>
        {{ freezer.name }}
      </a>
    </span>
    <confirm-modal
      :name="confirmModalId"
      :title="confirmFreezeTitle"
      :text="confirmFreezeText"
      :object="selectedInvoice"
      @confirmed="onConfirmFreezeInvoice"
    >
    </confirm-modal>
  </span>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import { BackendMixin } from '@/mixins/backend'
import { makeInvoice } from '@/types/payments'

export default {
  name: 'invoice-freeze',
  components: {
    ConfirmModal,
    LoadingGif,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    invoice: Object,
  },
  data() {
    return {
      loadingName: 'invoiceFreeze',
      freezer: null,
    }
  },
  computed: {
    confirmModalId() {
      return 'freeze-invoice-' + this.invoice.id
    },
    confirmFreezeTitle() {
      if (this.invoice.frozen) {
        return this.invoice.status
      } else {
        return this.freezer ? this.freezer.name : ''
      }
    },
    confirmFreezeText() {
      if (this.invoice.frozen) {
        return 'Voulez-vous débloquer la facture? La facture pourra de nouveau être payée'
      } else {
        return 'Voulez-vous bloquer la facture? La facture ne pourra plus être payée'
      }
    },
    selectedInvoice() {
      return {
        id: this.id,
        invoice: this.invoice,
        freezer: this.freezer,
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async freezeInvoice(freezer) {
      this.freezer = freezer
      this.$bvModal.show('bv-confirm-modal:' + this.confirmModalId)
    },
    async onConfirmFreezeInvoice() {
      try {
        const freezer = this.selectedInvoice.freezer
        const backendApi = new BackendApi('post', '/api/invoice-freeze/' + this.invoice.id + '/')
        const resp = await backendApi.callApi({ frozen: freezer ? freezer.id : null, })
        this.$emit('updated', makeInvoice(resp.data))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.invoice-freeze {
  margin: 2px 2px;
}
</style>
