<template>
  <div class="invoice" v-if="hasPerm('payments.view_invoice')">
    <page-header :title="title" icon="fa fa-file-invoice">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="invoice">
      <b-row>
        <b-col cols="3">
          <div v-if="invoice.createdOn">
            <b>Date: {{ invoice.createdOn | dateToString('dddd LL') }}</b>
            <div v-if="invoice.lastChange() !== invoice.createdOn">
              dernière modification le {{ invoice.lastChange() | dateToString('dddd LL') }}
            </div>
          </div>
        </b-col>
        <b-col cols="2">
          <h2><invoice-badge :invoice="invoice" no-link no-download></invoice-badge></h2>
        </b-col>
        <b-col cols="2">
          <payment-button
            :invoice="invoice"
            :entity="entity"
            v-if="!isLoading(loadingName) && invoice && (invoice.toBePaidPrice() > 0)"
            @paid="loadData()"
          ></payment-button>
        </b-col>
        <b-col cols="5">
          <div class="buttons text-right">
            <invoice-freeze :invoice="invoice" @updated="invoice = $event"></invoice-freeze>
            <invoice-send :invoice="invoice" @updated="invoice = $event" v-if="!entity.isOwn"></invoice-send>
            <invoice-download :invoice="invoice"></invoice-download>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9">
          <div class="entity-info" v-if="entity">
            <div class="entity-name">{{ entity.fullName() }}</div>
            <div class="entity-address" v-if="entity.city">
              <div v-for="line of entity.addressLines()" :key="line">
                {{ line }}
              </div>
              <div>{{ entity.zipCode }} {{ entity.city.name }} {{ entity.cedex }}</div>
            </div>
          </div>
        </b-col>
        <b-col class="text-right">
          <div class="entity-button" v-if="entity">
            <router-link :to="getEntityLink(entity)" class="btn btn-secondary btn-block">
              <span v-if="entity.family"><i class="fa-solid fa-people-roof"></i> Voir la fiche famille</span>
              <span v-else><i class="fa fa-contact-book"></i> Voir la fiche contact</span>
            </router-link>
          </div>
          <cancel-invoice-button :invoice="invoice" @done="onRefresh"></cancel-invoice-button>
        </b-col>
      </b-row>
      <b-row v-show="!isLoading(loadingName)">
        <b-col cols="6" class="members-col">
          <div class="table-header">
            <b-row>
              <b-col><h3>Montant total facturé</h3></b-col>
              <b-col class="text-right">
                <div class="total-value">{{ invoice.totalPrice | currency }}</div>
              </b-col>
            </b-row>
          </div>
          <div class="table-header">
            <b-row>
              <b-col><h3>Prestations</h3></b-col>
              <b-col class="text-right">
                {{ invoice.totalPrice | currency }}
              </b-col>
            </b-row>
          </div>
          <table class="table">
            <tr>
              <th class="td-date">Ventes</th>
              <th>&nbsp;</th>
              <th class="text-right">
              </th>
            </tr>
            <tr v-for="sale in sales" :key="sale.id">
              <td class="td-date" :class="sale.ignored ? 'ignored' : ''">
                {{ sale.createdOn | dateToString }}
              </td>
              <td :class="sale.ignored ? 'ignored' : ''">
                {{ sale.label }}
                <a href class="edit-label" v-if="canChangeSaleLabel" @click.prevent="changeSaleLabel(sale)">
                  <i class="fa fa-edit"></i>
                </a>
                <span class="badge badge-light" v-if="sale.analyticAccount">
                  {{ sale.analyticAccount.name }} {{ sale.schoolYear.name }}
                </span>&nbsp;
                <span v-if="sale.cancellation">
                  <span class="badge badge-warning" v-if="!sale.cancellationConfirmed">
                    Annulation en attente de confirmation
                  </span>
                  <span class="badge badge-warning" v-else-if="sale.refunded">
                    Annulation remboursée par avoir le {{ sale.cancelledOn | dateToString }}
                  </span>
                  <span class="badge badge-warning"  v-else-if="sale.ignored">
                    Annulation le {{ sale.cancelledOn | dateToString }}
                  </span>
                  <span class="badge badge-warning" v-else>Annulation payante</span>
                </span>
                <span v-else>
                  <span class="badge badge-warning" v-if="sale.refunded">
                    Annulation remboursée par avoir
                  </span>
                </span>
              </td>
              <td class="text-right" :class="sale.ignored ? 'ignored' : ''">
                {{ sale.price | currency }}
              </td>
            </tr>
          </table>
          <table class="table">
            <tr v-if="cancelledSales.length">
              <th class="td-date">Annulations</th>
              <th>&nbsp;</th>
              <th class="text-right">
              </th>
            </tr>
            <tr v-for="sale in cancelledSales" :key="sale.id">
              <td class="td-date">
                {{ sale.cancelledOn | dateToString }}
              </td>
              <td>
                {{ sale.label }}
                <a href class="edit-label" v-if="canChangeSaleLabel" @click.prevent="changeSaleLabel(sale)">
                  <i class="fa fa-edit"></i>
                </a>
                <span class="badge badge-light" v-if="sale.analyticAccount">
                  {{ sale.analyticAccount.name }} {{ sale.schoolYear.name }}
                </span>&nbsp;
                <span v-if="sale.cancellation">
                  <span class="badge badge-warning" v-if="!sale.cancellationConfirmed">
                    Annulation en attente de confirmation
                  </span>
                  <span class="badge badge-warning" v-else-if="sale.refunded">
                    Annulation remboursée par avoir de {{ sale.cancelledAmount | currency }}
                    le {{ sale.cancelledOn | dateToString }}
                  </span>
                  <span class="badge badge-warning"  v-else-if="!sale.ignored">
                    Annulation payante
                  </span>
                </span>
                <span v-else>
                  <span class="badge badge-warning" v-if="sale.refunded">
                    Remboursée par avoir {{ sale.cancelledAmount | currency }}
                    <span v-if="sale.cancelledOn">le {{ sale.cancelledOn | dateToString }}</span>
                  </span>
                </span>
              </td>
              <td class="text-right" :class="sale.ignored ? 'ignored' : ''">
                {{ sale.price | currency }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col>
          <div class="table-header">
            <b-row>
              <b-col><h3>Montant total payé</h3></b-col>
              <b-col class="text-right">
                <div class="total-value">{{ paidPrice | currency }}</div>
              </b-col>
            </b-row>
          </div>
          <div class="table-header">
            <b-row>
              <b-col><h3>Paiements</h3></b-col>
              <b-col class="text-right">
                {{ totalPayments | currency }}
              </b-col>
            </b-row>
          </div>
          <table class="table">
            <tr v-if="paymentContributions.length">
              <th colspan="3"><b>Numéraires</b></th>
            </tr>
            <tr v-for="contribution in paymentContributions" :key="contribution.id">
              <td class="td-date">
                {{ contribution.createdOn | dateToString }}
              </td>
              <td>
                {{ contribution.payment.paymentMode.name }} du {{ contribution.payment.paymentDate | dateToString }}
                de {{ contribution.payment.amount | currency }}
                <span v-if="contribution.payment.paymentMode.bank">
                  {{ contribution.payment.bankName }} {{ contribution.payment.bankNumber }}
                </span>
                <span v-if="contribution.payment.returned" class="badge badge-danger">
                  {{ contribution.payment.getReturnedStatus() }}
                </span>
                <span v-if="contribution.payment.entity.id !== invoice.entity.id" class="badge badge-danger">
                  famille {{ contribution.payment.entity.name }}
                </span>
              </td>
              <td class="text-right">
                <span v-if="contribution.amount">{{ contribution.amount | currency }}</span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr v-if="creditContributions.length">
              <th colspan="3"><b>Avoirs</b></th>
            </tr>
            <tr v-for="contribution in creditContributions" :key="contribution.id">
              <td class="td-date">
                {{ contribution.createdOn | dateToString }}
              </td>
              <td>
                {{ contribution.credit.amount | currency }} du {{ contribution.credit.createdOn | dateToString }}
                <span v-if="contribution.credit.entity.id !== invoice.entity.id" class="badge badge-danger">
                  famille {{ contribution.credit.entity.name }}
                </span>
                <div class="very-small-text" v-if="contribution.credit.comments">
                  {{ contribution.credit.comments }}
                </div>
              </td>
              <td class="text-right">
                <span v-if="contribution.amount">{{ contribution.amount | currency }}</span>
                <span v-else>-</span>
              </td>
            </tr>
          </table>
          <div v-if="cancellations.length">
            <div class="separator"></div>
            <div class="table-header">
              <b-row>
                <b-col><h3>Avoirs générés</h3></b-col>
                <b-col class="text-right">
                  {{ totalCredits | currency }}
                </b-col>
              </b-row>
            </div>
            <table class="table">
              <tr v-for="cancellation in cancellations" :key="cancellation.id">
                <td class="td-date">
                  {{ cancellation.createdOn | dateToString }}
                </td>
                <td>
                  <span v-if="cancellation.sale">
                    <span v-if="cancellation.sale.ignored">Annulation</span> {{ cancellation.sale.label }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="cancellation.credit.amount">{{ cancellation.credit.amount | currency }}</span>
                  <span v-else>-</span>
                </td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
    <change-sale-label-modal
      :sale="selectedSale"
      id="bv-change-sale-label-modal"
      @done="onLabelChanged"
    ></change-sale-label-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CancelInvoiceButton from '@/components/Invoices/CancelInvoiceButton'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import InvoiceDownload from '@/components/Invoices/InvoiceDownload'
import InvoiceSend from '@/components/Invoices/InvoiceSend'
import PaymentButton from '@/components/Payments/PaymentButton'
import InvoiceFreeze from '@/components/Invoices/InvoiceFreeze.vue'
import ChangeSaleLabelModal from '@/components/Invoices/ChangeSaleLabelModal.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeInvoice } from '@/types/payments'
import { BackendApi } from '@/utils/http'
import { compareDates } from '@/utils/sorting'

export default {
  name: 'invoice',
  props: {
    invoiceId: [String, Number],
  },
  mixins: [BackendMixin],
  components: {
    ChangeSaleLabelModal,
    InvoiceFreeze,
    CancelInvoiceButton,
    InvoiceDownload,
    InvoiceBadge,
    InvoiceSend,
    PaymentButton,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      invoice: null,
      loadingName: 'invoice-detail',
      selectedSale: null,
    }
  },
  created() {
    this.loadData()
  },
  watch: {
    invoiceId: function() { this.loadData() },
  },
  computed: {
    title() {
      if (this.invoice) {
        return 'Facture ' + this.invoice.getNumber()
      } else {
        return 'Facture'
      }
    },
    entity() {
      return this.invoice.entity
    },
    selectorDisabled() {
      return (store.getters.editMode !== '')
    },
    editMode() {
      return (store.getters.editMode !== '')
    },
    paidPrice() {
      if (this.invoice) {
        return this.invoice.paidPrice
      }
      return null
    },
    totalPayments() {
      if (this.invoice) {
        return this.invoice.totalPayments()
      }
      return null
    },
    totalCredits() {
      if (this.invoice) {
        return this.invoice.totalCredits()
      }
      return null
    },
    sales() {
      return this.invoice.sales.filter(elt => !elt.cancellation).concat([])
    },
    cancelledSales() {
      return this.invoice.sales.filter(elt => elt.cancellation).concat([])
    },
    contributions() {
      const contributions = this.invoice.contributions.filter(elt => elt.amount !== 0).concat([])
      return contributions.sort(
        (elt1, elt2) => compareDates(elt1.createdOn, elt2.createdOn)
      )
    },
    paymentContributions() {
      return this.contributions.filter(elt => (elt.payment !== null) && elt.payment.isVisible)
    },
    creditContributions() {
      return this.contributions.filter(elt => elt.credit !== null)
    },
    cancellations() {
      const cancellations = this.invoice.cancellations.filter(elt => elt.credit !== null).concat([])
      return cancellations.sort(
        (elt1, elt2) => compareDates(elt1.createdOn, elt2.createdOn)
      )
    },
    canChangeSaleLabel() {
      return this.hasPerm('payments.delete_sale')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      await this.loadInvoice()
    },
    async loadInvoice() {
      this.invoice = null
      this.startLoading(this.loadingName)
      try {
        let url = '/api/invoice-detail/' + this.invoiceId + '/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.invoice = makeInvoice(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getLinks() {
      return []
    },
    getEntityLink(entity) {
      if (entity.family) {
        return { name: 'families-detail', params: { entityId: '' + entity.id, }, query: { tab: 'invoices', }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + entity.id, }, query: { tab: 'invoices', }, }
      }
    },
    onRefresh() {
      this.loadData()
    },
    changeSaleLabel(sale) {
      this.selectedSale = sale
      const that = this
      this.$nextTick(
        () => {
          that.$bvModal.show('bv-change-sale-label-modal')
        }
      )
    },
    onLabelChanged(sale) {
      let index = this.sales.map(elt => elt.id).indexOf(sale.id)
      if (index >= 0) {
        this.sales[index].label = sale.label
        this.sales = [].concat(this.sales)
      } else {
        index = this.cancelledSales.map(elt => elt.id).indexOf(sale.id)
        if (index >= 0) {
          this.cancelledSales[index].label = sale.label
          this.cancelledSales = [].concat(this.cancelledSales)
        }
      }
    },
  },
}
</script>

<style lang="less">
  ul.invoice-detail {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.invoice-detail li {
    padding: 4px;
    margin-bottom: 4px;
    border-bottom: solid 1px #ccc;
  }

  ul.invoice-detail li:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }

  .table-header {
    background: #454851;
    color: #fff;
    margin-top: 20px;
    padding: 4px 10px;
  }

  .table-header h3 {
    margin: 0;
    padding: 0;
  }

  .invoice table.table tr td {
    text-align: left;
    padding: 4px 10px;
  }

  .invoice table.table tr th {
    background: #a0a0a0;
    padding: 4px 10px;
  }

  .invoice table.table tr td.text-right {
    text-align: right;
  }

  .invoice table.table tr td.td-date {
    width: 120px;
  }
  .buttons a {
    margin-left: 10px;
  }
  .entity-info {
    padding: 5px;
    border: solid 1px #888;
    border-radius: 4px;
    margin: 20px 0;
  }
  .entity-name {
    font-size: 1.1em;
    font-weight: bold;
  }
  .entity-button {
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .total-value {
    padding: 5px 10px;
    border-radius: 4px;
    background: #f2f2a2;
    color: #000;
    display: inline-block;
    font-weight: bold;
  }
  .payment-buttons a{
    display: inline-block;
    margin: 0 1px;
    color: #505050 !important;
  }
  .ignored {
    text-decoration: line-through;
  }
  .very-small-text {
    font-size: 9px;
  }
  .edit-label {
    margin:  0 2px;
    font-size: 11px;
  }
</style>
