<template>
  <span v-if="invoice && !invoice.isCancelled && hasPerm('payments.delete_invoice')">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <span
      v-b-tooltip="'Les ventes passeront à facturer et les paiements généreront un avoir du montant correspondant'"
    >
      <a class="btn btn-danger btn-sm" href @click.prevent="cancelInvoice" v-if="!isLoading(loadingName)">
        <i class="fa fa-warning"></i> Annuler la facture
      </a>
    </span>
    <b-modal dialog-class="modal-md"
      :id="getId()"
      cancel-title="Ne pas annuler la facture"
      @ok.prevent="onOk"
      ok-variant="danger"
      ok-title="Confirmer l'annulation"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-warning"></i> Annuler une facture</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="12">
          <div class="alert-warning">
            Voulez-vous annuler la facture {{ invoice.getNumber() }} de {{ invoice.totalPrice | currency }} du
            {{ invoice.createdOn | dateToString }} <span v-if="invoice.entity.name">de {{ invoice.entity.name }}</span>
          </div>
          <div class="alert-warning">
            Toutes les ventes associées passeront de nouveau à facturer et les éventuelles paiements
            généreront un avoir du montant des paiements effectués sur cette facture
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { getRandomId } from '@/utils/random'

export default {
  name: 'CancelInvoiceButton',
  components: {
    LoadingGif,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    invoice: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      loadingName: 'CancelInvoice',
      errorText: '',
    }
  },
  watch: {
    invoice: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getId() {
      return 'bv-modal-cancel-invoice' + this.randomId
    },
    cancelInvoice() {
      this.$bvModal.show(this.getId())
    },
    async onOk() {
      this.errorText = ''
      try {
        this.startLoading(this.loadingName)
        const url = '/api/cancel-invoice/' + this.invoice.id + '/'
        const backendApi = new BackendApi('post', url)
        await backendApi.callApi({ confirm: true, })
        this.$emit('done')
        this.endLoading(this.loadingName)
        this.$bvModal.hide(this.getId())
        await this.addSuccess('La facture a été annulée')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.alert-warning {
  padding: 3px 10px;
  margin-bottom: 10px;
}
</style>
